import React, { ReactElement, useEffect, useState } from "react";
import SearchInput from "../../components/common/SearchInput";

import styles from "./index.module.css";
import { Recipient } from "../../types/recipient";
import { getRecipientsByUnitId, requestCallReq } from "../../api/requests";
import { UserIcon } from "../../components/icons/UserIcon";
import { FullPageLoader } from "../../components/common/FullPageLoader";
import { highlightMatch } from "../../utils/highlight-match";
import { envVars } from "../../utils/env-vars";

const TWILIO_NUMBER = envVars.TWILIO_PHONE_NUMBER;

export const PorttelefonPage: React.FC = (): ReactElement => {
  //   const { id: unitId } = useParams() as { id: string };
  const unitId = "1"; // replace with useParams later
  const [isLoading, setIsLoading] = useState(true);
  const [locationAddress, setLocationAddress] = useState("");
  const [users, setUsers] = useState<Recipient[]>([]);
  const [searchInput, setSearchInput] = useState("");

  const filteredUsers = users.filter((user) =>
    `${user.name} ${user.surname}`
      .toLowerCase()
      .includes(searchInput.toLowerCase())
  );

  const setSearchInputHandler = (search: string) => {
    setSearchInput(search);
  };

  const fetchUsers = async () => {
    try {
      if (+unitId) {
        const response = await getRecipientsByUnitId(+unitId);
        setUsers(response.data.recipients);
        setLocationAddress(response.data.locationAddress);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const triggerCall = async (recipientId: number) => {
    await requestCallReq(recipientId);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className={styles.root}>
      <FullPageLoader show={isLoading} />
      <>
        <h1 className={styles.title}>Porttelefon</h1>
        <h1 className={styles.locationTitle}>{locationAddress}</h1>
        <div className={styles.inputContainer}>
          <SearchInput
            searchInput={searchInput}
            setSearchInputHandler={setSearchInputHandler}
          />
        </div>
        <div className={styles.list}>
          {filteredUsers.map((user) => {
            const id = user.id;
            const name = `${user.name} ${user.surname}`;
            const highlightedName = highlightMatch(name, searchInput);

            if (!id) return null;

            return (
              <div key={user.id} className={styles.listItem}>
                <div className={styles.nameContainer}>
                  <UserIcon />
                  <h3 className={styles.name}>{highlightedName}</h3>
                </div>

                <a
                  href={`tel:${TWILIO_NUMBER}`}
                  onClick={() => triggerCall(+id)}
                >
                  <img src="/assets/call-icon.png" alt="Call" height={50} />
                </a>
              </div>
            );
          })}
        </div>
      </>
    </div>
  );
};
