import React from "react";

import styles from "./index.module.css";
import { SearchIcon } from "../../icons/SearchIcon";

type Props = {
  searchInput: string;
  setSearchInputHandler: (search: string) => void;
};

const SearchInput = ({ searchInput, setSearchInputHandler }: Props) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputHandler(e.target.value);
  };

  return (
    <div className={styles.searchContainer}>
      <input
        type="text"
        className={styles.searchInput}
        placeholder="Search"
        value={searchInput}
        onChange={handleInputChange}
      />
      <div className={styles.searchIcon}>
        <SearchIcon />
      </div>
    </div>
  );
};

export default SearchInput;
