import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { envVars } from "../../utils/env-vars";

export const RedirectToPorttelefonPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(envVars.REACT_APP_DEFAULT_ROUTE);
  }, [navigate]);

  return null;
};
