import { Route, Routes } from "react-router-dom";

import { PorttelefonPage } from "./PorttelefonPage";
import { RedirectToPorttelefonPage } from "./RedirectToPorttelefonPage";
import { envVars } from "../utils/env-vars";

export const IndexPage = () => {
  return (
    <>
      <Routes>
        <Route
          path={envVars.REACT_APP_DEFAULT_ROUTE}
          element={<PorttelefonPage />}
        />
        <Route path="*" element={<RedirectToPorttelefonPage />} />
        {/* Add more routes here if needed */}
      </Routes>
    </>
  );
};
