export const highlightMatch = (text: string, searchTerm: string) => {
  if (!searchTerm) return text;
  const regex = new RegExp(`(${searchTerm})`, "gi"); // Create a regex for the search term (case-insensitive)
  const parts = text.split(regex); // Split the text based on the regex

  return parts.map((part, index) =>
    part.toLowerCase() === searchTerm.toLowerCase() ? (
      <span key={index} style={{ fontWeight: "bold" }}>
        {part}
      </span>
    ) : (
      part
    )
  );
};
