import { AxiosResponse } from "axios";
import axiosClient from "./axiosClient";
import { Recipient } from "../types/recipient";

export const getRecipientsByUnitId = (id: number) => {
  return axiosClient.get<
    undefined,
    AxiosResponse<{
      locationAddress: string;
      recipients: Recipient[];
    }>
  >(`/units/${id}/recipients`);
};

export const requestCallReq = async (recipientId: number) => {
  return axiosClient.post(`/calls/request-call`, { recipientId });
};
